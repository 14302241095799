@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
  height: 100%;
  background: black;
  color: white;
}

:root {
  --bs-info-text-emphasis: #14EAD1;
}

body {
  background: black;
  color: white;
  margin: 0;
  font-family: "Verdana", 'Helvetica Neue', -apple-system, sans-serif, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 0.9rem;
}